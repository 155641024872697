<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page

========================================================================================== -->

<template>
  <div id="page-user-view">
    <vs-alert :active.sync="userNotFound" color="danger" title="User Not Found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link to="/" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <div v-if="userData">
      <!--- TOP USER BANNER START --->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <div
            class="rounded-lg w-full"
            style="
              background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
              box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
            "
          >
            <div class="knowledge-base-jumbotron-content rounded-lg lg:p-12 sm:p-10 p-8" style="backdrop-filter: blur(10px)">
              <div class="flex justify-between items-center">
                <div>
                  <h5 class="font-light text-white ml-2">{{ userData.role.replaceAll('_', ' ') | capitalize }}</h5>
                  <h1 class="text-white font-bold" style="font-size: 4rem">{{ userData.fullName }}</h1>
                </div>
                <!-- <img src="https://portal-cdn.rapid.education/profile-pictures/default.jpeg" class="rounded-lg" width="120" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--- TOP USER BANNER END --->

      <!--- USER ACTION BAR START --->
      <div class="vx-row mb-6 match-height">
        <div class="vx-col grid grid-cols-3 gap-8 w-full">
          <vx-card
            class="p-6 cursor-pointer text-white"
            @click="editUserInit"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-primary), 0.4), rgba(var(--vs-primary), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="EditIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Edit<br />User</h2>
            </div>
          </vx-card>
          <vx-card
            class="p-6 cursor-pointer text-white"
            @click="courseSearchActive = true"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-success), 0.4), rgba(var(--vs-success), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="BookIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Assign<br />Course</h2>
            </div>
          </vx-card>
        </div>
      </div>
      <!--- USER ACTION BAR END --->

      <!--- USER SHOW INFORMATION START--->
      <div class="vx-row mb-6 match-height">
        <div class="vx-col w-1/3">
          <vx-card class="w-full bg-dark flex items-center">
            <h3 class="font-bold">User Id</h3>
            <h3 class="font-light">{{ userData.id }}</h3>
          </vx-card>
        </div>
        <div class="vx-col w-1/3">
          <vx-card class="w-full bg-dark flex items-center">
            <h3 class="font-bold">User Email</h3>
            <h3 class="font-light">{{ userData.email }}</h3>
          </vx-card>
        </div>
      </div>
      <!--- USER SHOW INFORMATION END--->

      <!--- USER SHOW SUBSCRIPTIONS START--->
      <div class="vx-row">
        <div class="vx-col w-full">
          <list-subscriptions title="User Subscriptions" :key="subTableKey" :userId="userData.id" :filterUsers="false" />
        </div>
      </div>
      <!--- USER SHOW SUBSCRIPTIONS START--->

      <vs-popup :active.sync="userEditActive" title="Edit User">
        <div class="popup-input">
          <p class="mb-1 font-semibold">First Name</p>
          <vs-input v-model="userEditData.firstName" class="mb-3 w-full" />

          <p class="mb-1 font-semibold">Last Name</p>
          <vs-input v-model="userEditData.lastName" class="mb-3 w-full" />

          <p class="mb-1 font-semibold">Email</p>
          <vs-input v-model="userEditData.email" class="mb-3 w-full" />

          <p class="mb-1 font-semibold">Phone</p>
          <vs-input v-model="userEditData.phone" class="mb-3 w-full" />

          <p class="mb-1 font-semibold">New Password</p>
          <vs-input v-model="userEditData.password" class="mb-3 w-full" />

          <p class="mb-1 font-semibold">Status</p>
          <div class="flex items-center mb-3">
            <vs-radio v-model="userEditData.status" vs-name="userStatus" vs-value="active" class="mr-4">Active </vs-radio>
            <vs-radio v-model="userEditData.status" vs-name="userStatus" vs-value="disabled" class="mr-4">Disabled </vs-radio>
          </div>

          <vs-button color="success" type="filled" @click="editUserSave">Save Changes</vs-button>
        </div>
      </vs-popup>
      <vs-popup :active.sync="courseSearchActive" :title="`Select Course To Enroll Student In`">
        <vs-table :data="courseSearchResults" :sst="true" max-items="5" search @search="addStudentCourseSearch">
          <template slot="header">
            <h3 class="ml-4">Courses</h3>
          </template>

          <template slot="thead">
            <vs-th>Id</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach.id">
              <vs-td :data="attach.id">
                {{ attach.id }}
              </vs-td>

              <vs-td :data="`${attach.name}`">
                <div class="flex align-middle items-center">
                  <img :src="attach.thumbnail" class="rounded mr-3" width="40px" />
                  {{ `${attach.name}` }}
                </div>
              </vs-td>

              <vs-td>
                <vs-button color="primary" size="small" type="border" @click="addStudentToCourseApps(attach)"> Assign </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
      <vs-popup
        :active.sync="appSearchActive"
        title="Select Enabled Applications
      "
      >
        <vs-table :data="appSearchResults" :sst="true" max-items="5" search @search="addStudentAppsSearch" class="mb-2">
          <template slot="header">
            <h3 class="ml-4">Search Apps</h3>
          </template>

          <template slot="thead">
            <vs-th>Id</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach.id" v-if="isAppEnabled(attach.id)">
              <vs-td :data="attach.id">
                {{ attach.id }}
              </vs-td>

              <vs-td :data="`${attach.name}`">
                <div class="flex align-middle items-center">
                  <img :src="attach.icon" class="rounded mr-3" width="40px" />
                  {{ `${attach.name}` }}
                </div>
              </vs-td>

              <vs-td>
                <vs-button color="primary" size="small" type="border" @click="addStudentToCourseAssignApp(attach)"> Assign </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-divider />
        <vs-table :data="appsSelected" max-items="5" pagination class="mt-2">
          <template slot="header">
            <h3 class="ml-4 mt-4 mb-3">Assigned Apps</h3>
          </template>

          <template slot="thead">
            <vs-th>Id</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="(attach, index) in data" :key="attach.id">
              <vs-td :data="attach.id">
                {{ attach.id }}
              </vs-td>

              <vs-td :data="`${attach.name}`">
                <div class="flex align-middle items-center">
                  <img :src="attach.icon" class="rounded mr-3" width="40px" />
                  {{ `${attach.name}` }}
                </div>
              </vs-td>

              <vs-td>
                <vs-button color="primary" size="small" type="border" @click="appsSelected.splice(index, 1)"> Remove </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-button color="primary" type="filled" class="mt-3" @click="addStudentToCourseCalendar">Next Step</vs-button>
      </vs-popup>
      <vs-popup :active.sync="calendarActive" title="Assign Course To User - Select Dates">
        <div class="mb-4">
          <h6>Choose Subscription Expiry</h6>
          <datepicker :inline="true" placeholder="Select Date" v-model="calendarSelect" class="datepicker-popup-inline mt-4"></datepicker>
          <ul class="flex mt-5" :key="courseAssignKey">
            <li>
              <vs-radio v-model="calendarDuration" @input="calculateCalendarDate" vs-value="1" class="mr-4">1 Month </vs-radio>
            </li>
            <li>
              <vs-radio v-model="calendarDuration" @input="calculateCalendarDate" vs-value="3" class="mr-4">3 Months </vs-radio>
            </li>
            <li>
              <vs-radio v-model="calendarDuration" @input="calculateCalendarDate" vs-value="6" class="mr-4">6 Months </vs-radio>
            </li>
            <li>
              <vs-radio v-model="calendarDuration" @input="calculateCalendarDate" vs-value="12" class="mr-4">1 Year </vs-radio>
            </li>
          </ul>
        </div>
        <p class="text-danger font-bold" v-if="calendarSelect === null">You Must Choose A Subscription Duration</p>

        <vs-button color="primary" type="filled" @click="addStudentToCourseEnroll(courseAssignSelected)" v-if="calendarSelect"
          >Assign Student
        </vs-button>
      </vs-popup>
    </div>
    <div class="simple-spinner" v-else>
      <span></span>
    </div>
  </div>
</template>

<script>
import ListSubscriptions from '../../../subscriptions/admin/subscription-list/ListSubscriptions';
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    ListSubscriptions,
    Datepicker,
  },
  data() {
    return {
      cardBgImgURL: 'https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/blue-wave-bg.png',

      userData: null,

      userNotFound: false,
      activeCourseData: null,

      userEditActive: false,
      userEditData: {
        id: null,
        email: null,
        phone: null,
        firstName: null,
        lastName: null,
        password: null,
        status: 'active',
      },

      courseSearchActive: false,
      courseSearchResults: [],

      appSearchActive: false,
      appSearchResults: [],
      appsSelected: [],

      calendarActive: false,
      calendarDuration: null,
      calendarSelect: null,

      courseAssignKey: 0,
      courseAssignSelected: {},

      subTableKey: 0,
    };
  },
  methods: {
    addStudentCourseSearch(term) {
      this.$http.get(`course?search=${term}&pageSize=5`).then((response) => {
        if (response.data) {
          this.courseSearchResults = response.data.rows;
        }
      });
    },
    addStudentAppsSearch(term) {
      this.$http.get(`platform/app?search=${term}&filter[id]=${this.courseAssignSelected.appsEnabled}&pageSize=5`).then((response) => {
        if (response.data) {
          this.appSearchResults = response.data.rows;
        }
      });
    },
    addStudentToCourseApps(course) {
      this.courseSearchActive = false;
      this.appSearchActive = true;
      this.courseAssignSelected = course;
      this.addStudentAppsSearch('');
    },
    addStudentToCourseCalendar() {
      this.appSearchActive = false;
      this.calendarActive = true;
    },
    addStudentToCourseAssignApp(app) {
      let addApp = true;
      this.appsSelected.forEach((item) => {
        if (item.id === app.id) {
          addApp = false;
          return false;
        }
      });

      if (addApp === true) {
        this.appsSelected.push(app);
      } else {
        this.$vs.notify({
          title: 'App Already Assigned',
          text: 'The app you tried to assign has already been assigned',
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle',
        });
      }
    },
    addStudentToCourseEnroll(course) {
      this.$vs.loading();

      if (!this.calendarSelect) {
        this.$vs.notify({
          color: 'danger',
          title: 'Failed to create Subscription',
          text: 'Please provide subscription duration.',
          icon: 'feather',
          iconPack: 'icon-x-circle',
          position: 'top-right',
        });
        return this.$vs.loading.close();
      }

      const apps = [];
      this.appsSelected.forEach((app) => {
        apps.push(app.id);
      });

      const pl = {
        userId: this.userData.id,
        courseId: course.id,
        apps,
      };

      pl.expiresAt = this.calendarSelect.getTime();
      this.$http
        .post('billing/subscription', pl)
        .then((response) => {
          this.$vs.loading.close();

          if (response.status === 200) {
            return this.$vs.notify({
              color: 'success',
              title: 'Subscription Created',
              text: 'You have successfully created a subscription',
              icon: 'feather',
              iconPack: 'icon-check-circle',
              position: 'top-right',
            });
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to create Subscription',
            text: exception.response.data.error.description,
            icon: 'feather',
            iconPack: 'icon-x-circle',
            position: 'top-right',
          });
        });

      this.subTableKey++;
      this.calendarActive = false;
      this.courseSearchActive = true;
    },
    calculateCalendarDate() {
      const date = new Date();
      const months = parseInt(this.calendarDuration);
      date.setMonth(date.getMonth() + months);
      this.calendarSelect = date;
    },
    editUserInit() {
      this.userEditActive = true;
      this.userEditData = this.userData;
    },
    editUserSave() {
      this.$http
        .patch(`user/${this.userData.id}`, this.userEditData)
        .then((response) => {
          if (response.data) {
            this.loadUserData();
            if (this.userEditData.password) {
              this.$vs.notify({
                title: 'User Password Updated',
                text: `Updated users password with name ${this.userEditData.firstName} ${this.userEditData.lastName} successfully`,
                color: 'warning',
                iconPack: 'feather',
                position: 'bottom-right',
                icon: 'icon-info',
              });
            }
            return this.$vs.notify({
              title: 'Updated User',
              text: `Updated user with name ${this.userEditData.firstName} ${this.userEditData.lastName} successfully`,
              color: 'success',
              iconPack: 'feather',
              position: 'bottom-right',
              icon: 'icon-check-circle',
            });
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to Update User',
            text: exception.response.data.error.description,
            icon: 'feather',
            iconPack: 'icon-x-circle',
            position: 'top-right',
          });
        });
    },
    isAppEnabled(id) {
      return !this.appsSelected.some((app) => app.id === id);
    },
    loadUserData() {
      const userId = this.$route.params.userId;

      this.$http
        .get(`user/${userId}?admin=true`)
        .then((response) => {
          if (response.data) {
            this.userData = response.data;
            const pictureId = this.userData.avatar || 'default';
            this.userData.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${pictureId}.jpeg`;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.userNotFound = true;
          }
        });
    },
  },
  created() {
    this.addStudentCourseSearch('');
    this.loadUserData();
  },
  beforeDestroy() {
    this.userData = null;
    this.activeCourseData = null;
  },
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1700px) {
    .image-course {
      display: none;
    }
    .course-col {
      width: 0 !important;
      min-height: 2.05vh;
    }
    .welcome-col {
      width: 100% !important;
    }
  }
}
</style>
